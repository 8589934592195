body {
    overflow: hidden;
}

.main {
    width: 100vw;
    height: 100vh;
}

.card{
    position: absolute;
}

.img {
    width: 100%;
}

.video {
    width: 100%;
}


.delete {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 48px;
    height: 48px;
}

.deleteicon {
    display: none;
}

.card:hover .deleteicon {
    display: block;
}